import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  ModalProps,
  Typography,
} from "@mui/material";
import { Restricted } from "components/RolesManagement/Restricted";
import { IUser } from "helpers/interfaces";
import useFetch from "hooks/useFetch";
import { API } from "utils/axios/paths";

interface EditRoleModalProps extends Partial<ModalProps> {
  user: IUser;
  open: boolean;
  onClose: () => void;
}

const EditRoleModal = ({ user, ...others }: EditRoleModalProps) => {
  const { call: newManager, isLoading: upgrading } = useFetch(
    "post",
    API.ADMIN.ROLE_MANAGER(user?.email || "")
  );
  const { call: deleteManager, isLoading: downgrading } = useFetch(
    "delete",
    API.ADMIN.ROLE_MANAGER(user?.email || "")
  );

  if (!user) return null;

  if (!user.role) return null;

  return (
    <Restricted to={["ADMIN"]}>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...others}
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          {user.role === "ADMIN" ? (
            <CardContent>
              <Alert severity="error">
                You cannot edit the role of an Admin user
              </Alert>
            </CardContent>
          ) : (
            <>
              <CardContent>
                <Typography
                  sx={{ fontSize: "meduim" }}
                  variant="h4"
                  component="h2"
                >
                  Edit Role for user :
                </Typography>
                <Typography
                  color={"primary"}
                  variant="subtitle1"
                  component="h2"
                >
                  {user.email}
                </Typography>
                <Alert severity="info" sx={{ my: 2 }}>
                  The current role is :{" "}
                  <strong>
                    {user.role === "MANAGER" ? "Manager" : "User"}
                  </strong>
                </Alert>
              </CardContent>
              <CardActions>
                {user.role === "MANAGER" ? (
                  <LoadingButton
                    loading={upgrading || downgrading}
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      await deleteManager({});
                      others.onClose();
                    }}
                  >
                    Change to User
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={upgrading || downgrading}
                    onClick={async () => {
                      await newManager({});
                      others.onClose();
                    }}
                  >
                    Change to Manager
                  </LoadingButton>
                )}
              </CardActions>
            </>
          )}
        </Card>
      </Modal>
    </Restricted>
  );
};

export default EditRoleModal;
