import { ITranslation } from "./i18n";

const translationEs: ITranslation = {
  welcome: "Bienvenido",
  logout: "Cerrar sesión",
  navbar: {
    home: "Inicio",
    offers: "Ofertas",
    dashboard: "Tablero",
    about: "Acerca de",
    contact: "Contacto",
    impressum: "Impressum",

  },
  home: {
    hero: {
      title: "Comienza tu viaje con",
      company: "Fuerza Laboral Calificada",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, perferendis!",
      userSpace: "Espacio de Usuario",
      browseOffers: "Explora nuestras ofertas",
    },
    partners: {
      title: "Confiado por nuestros mejores socios",
    },
    reviews: {
      title: "Opiniones",
      description:
        "Descubre lo que nuestros clientes aman de nuestros productos. Descubre cómo destacamos en eficiencia, durabilidad y satisfacción. Únete a nosotros por calidad, innovación y soporte confiable.",
    },
  },
  signIn: {
    title: "Iniciar sesión",
    dontHaveAcount: "¿No tienes una cuenta?",
    forgotPass: "¿Olvidaste tu contraseña?",
    signinto: "Inicia sesión en ...",
    enterDetails: "Ingrese sus detalles a continuación",
  },
  signUp: {
    title: "Regístrate",
    firstName: "Nombre",
    lastName: "Apellido",
    userName: "Nombre de usuario",
    email: "Correo electrónico",
    password: "Contraseña",
    confirmPassword: "Confirmar contraseña",
    cerateAccount: "Crear cuenta",
    getStartedFree: "Empieza gratis.",
    AlreadyHave: "¿Ya tienes una cuenta?",
    error: {
      alreadyExist: "El correo electrónico ya existe",
      failed: "Registro fallido",
    },
  },
  header: {
    home: "Inicio",
    feed: "Feed",
    authors: "Autores",
    explore: "Explorar",
    blog: "Blog",
    contact: "Contacto",
  },
  settings: {
    profile: "Perfil",
    notifications: "Notificaciones",
    email: "Correo electrónico",
    privacySecurity: "Privacidad y Seguridad",
  },
  messages: {
    somthingWentWrong: "Algo salió mal",
    emailOrPasswordIncorrect: "Correo electrónico o contraseña incorrectos",
    success: "Éxito",
    noResult: "Sin resultados",
  },

  footer: {
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    company: {
      title: "Compañía",
      aboutUs: "Sobre nosotros",
      contact: "Contacto",
    },
    legal: {
      title: "Legal",
      terms: "Términos",
      privacy: "Privacidad",
    },
  },
  profile: {
    title: "Perfil",
    editProfileMsg: "La información se puede editar",
    general: "General",
    resumes: "Currículums",
    pageTitle: "Usuario: Configuración de cuenta",
  },
  offers: {
    title: "Ofertas",
    helmet: "Explorar Ofertas",
    search: "Buscar",
    rowsPerPage: "Filas por página",
    application: "Solicitud",
    category: "Categoría",
    requirements: "Requisitos",
    description: "Descripción",
    reqMsg: "Lea los requisitos detenidamente antes de aplicar",
    alreadySubmited: "ya enviado",
    gotoApplication: "ir a la aplicación",
    submitApplication: "Enviar solicitud",
    loginToSubmit: "Inicia sesión para enviar",
  },
  form: {
    errors: {
      errorUploadingResume:
        "Error al subir el currículum, por favor inténtelo de nuevo!",
    },
    btn: {
      saveChanges: "Guardar cambios",
      savePhoto: "Guardar foto",
      saved: "Guardado",
      cancel: "Cancelar",
      dropOrSelect: "Soltar o seleccionar archivo",
      dropHere: "Soltar archivos aquí o hacer clic",
      browse: "Buscar",
      throughtComputer: "a través de tu ordenador",
    },
    validation: {
      firstNameRequired: "Se requiere el nombre",
      lastNameRequired: "Se requiere el apellido",
      invalidEmail: "Debe ser un correo electrónico válido",
      emailRequired: "Se requiere el correo electrónico",

      oldPasswordRequired: "Se requiere la contraseña antigua",
      newPasswordRequired: "Se requiere la nueva contraseña",
      newPasswordMin: "La contraseña debe tener al menos 6 caracteres",
      passwordsMustMatch: "Las contraseñas deben coincidir",

      firstNameMin: "¡Demasiado corto!",
      firstNameMax: "¡Demasiado largo!",
      lastNameMin: "¡Demasiado corto!",
      lastNameMax: "¡Demasiado largo!",
      phoneInvalid: "El número de teléfono no es válido",
      phoneRequired: "Se requiere el número de teléfono",
      passwordRequired: "Se requiere la contraseña",
      passwordMin: "La contraseña debe tener al menos 8 caracteres",
      passwordLowercase:
        "La contraseña debe contener al menos una letra minúscula",
      passwordNumber: "La contraseña debe contener al menos un número",
    },
    labels: {
      firstname: "Nombre",
      lastname: "Apellido",
      email: "Correo electrónico",
      phone: "Teléfono",
      password: "Contraseña",
      oldPassword: "Contraseña anterior",
      newPassword: "Nueva contraseña",
      confirmNewPassword: "Confirmar nueva contraseña",
    },
    allowed: "Permitido",
    maxSize: "Tamaño máximo de ",
  },
};

export default translationEs;
