import { AccountCircleOutlined, SecurityOutlined } from "@mui/icons-material";
import { PATHS } from "routes/paths";
import { RestrectedTo } from "./interfaces";
import { useTranslation } from "react-i18next";

export interface INavItem {
  title: string;
  path: string;
  icon?: any;
  restricted?: RestrectedTo[];
  children?: INavItem[];
}

export const sampleNavItems: INavItem[] = [
  {
    title: "home",
    path: "/",
  },
  {
    title: "offers",
    path: PATHS.OFFERS.ROOT,
    restricted: ["LOGGED_IN"],
  },
  {
    title: "dashboard",
    path: PATHS.DASHBOARD.ROOT,
    restricted: ["MANAGER", "ADMIN"],
  },
  {
    title: "about",
    path: "/about",
  },
  {
    title: "contact",
    path: "/contact",
  },
  {
    title: "impressum",
    path: "/impressum",
  },
];

const ICONS = {
  // dashboard: getIcon("ic_dashboard"),
  // analytics: getIcon("ic_analytics"),
  // user: getIcon("ic_user"),
  // courses: getIcon("ic_courses"),
  // quiz: getIcon("ic_quiz"),
  // courses:getIcon('ic_courses')
};

export type INavList = INavItem[];

export const settingsItems: INavList = [
  { title: "profile", path: "/", icon: AccountCircleOutlined },
  { title: "privacySecurity", path: "/", icon: SecurityOutlined },
];
