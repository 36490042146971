import { ITranslation } from "./i18n";

const translationDe: ITranslation = {
  welcome: "Willkommen",
  logout: "Ausloggen",
  navbar: {
    home: "Startseite",
    offers: "Angebote",
    dashboard: "Dashboard",
    about: "Über uns",
    contact: "Kontakt",
    impressum: "Impressum",
  },
  home: {
    hero: {
      title: "Starten Sie Ihre Reise mit",
      company: "Qualifizierter Arbeitskräfte",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, perferendis!",
      userSpace: "Benutzerbereich",
      browseOffers: "Unsere Angebote durchsuchen",
    },
    partners: {
      title: "Vertrauenswürdig bei unseren besten Partnern",
    },
    reviews: {
      title: "Bewertungen",
      description:
        "Erfahren Sie, was unsere Kunden an unseren Produkten lieben. Entdecken Sie, wie wir in Effizienz, Haltbarkeit und Zufriedenheit glänzen. Begleiten Sie uns für Qualität, Innovation und zuverlässige Unterstützung.",
    },
  },
  signIn: {
    title: "Anmelden",
    dontHaveAcount: "Sie haben kein Konto?",
    forgotPass: "Passwort vergessen?",
    signinto: "Anmelden bei ...",
    enterDetails: "Geben Sie Ihre Details unten ein",
  },
  signUp: {
    title: "Registrieren",
    firstName: "Vorname",
    lastName: "Nachname",
    userName: "Benutzername",
    email: "E-Mail-Adresse",
    password: "Passwort",
    confirmPassword: "Passwort bestätigen",
    cerateAccount: "Konto erstellen",
    getStartedFree: "Kostenlos starten.",
    AlreadyHave: "Sie haben bereits ein Konto?",
    error: {
      alreadyExist: "E-Mail existiert bereits",
      failed: "Registrierung fehlgeschlagen",
    },
  },
  header: {
    home: "Startseite",
    feed: "Feed",
    authors: "Autoren",
    explore: "Erkunden",
    blog: "Blog",
    contact: "Kontakt",
  },
  settings: {
    profile: "Profil",
    notifications: "Benachrichtigungen",
    email: "E-Mail",
    privacySecurity: "Datenschutz und Sicherheit",
  },
  messages: {
    somthingWentWrong: "Etwas ist schief gelaufen",
    emailOrPasswordIncorrect: "E-Mail oder Passwort falsch",
    success: "Erfolgreich",
    noResult: "Kein Ergebnis",
  },

  footer: {
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    company: {
      title: "Firma",
      aboutUs: "Über uns",
      contact: "Kontakt",
    },
    legal: {
      title: "Rechtliches",
      terms: "Nutzungsbedingungen",
      privacy: "Datenschutz",
    },
  },
  profile: {
    title: "Profil",
    editProfileMsg: "Die Informationen können bearbeitet werden",
    general: "Allgemein",
    resumes: "Lebensläufe",
    pageTitle: "Benutzer: Kontoeinstellungen",
  },
  offers: {
    title: "Angebote",
    helmet: "Angebote durchsuchen",
    search: "Suche",
    rowsPerPage: "Zeilen pro Seite",
    application: "Bewerbung",
    category: "Kategorie",
    requirements: "Anforderungen",
    description: "Beschreibung",
    reqMsg:
      "Lesen Sie die Anforderungen sorgfältig durch, bevor Sie sich bewerben",
    alreadySubmited: "bereits eingereicht",
    gotoApplication: "zur Bewerbung gehen",
    submitApplication: "Bewerbung absenden",
    loginToSubmit: "Einloggen, um abzusenden",
  },
  form: {
    errors: {
      errorUploadingResume:
        "Fehler beim Hochladen des Lebenslaufs, bitte versuchen Sie es erneut!",
    },
    btn: {
      saveChanges: "Änderungen speichern",
      savePhoto: "Foto speichern",
      saved: "Gespeichert",
      cancel: "Abbrechen",
      dropOrSelect: "Datei ablegen oder auswählen",
      dropHere: "Dateien hier ablegen oder klicken",
      browse: "Durchsuchen",
      throughtComputer: "über Ihren Computer",
    },
    validation: {
      firstNameRequired: "Vorname ist erforderlich",
      lastNameRequired: "Nachname ist erforderlich",
      invalidEmail: "Muss eine gültige E-Mail sein",
      emailRequired: "E-Mail ist erforderlich",

      oldPasswordRequired: "Altes Passwort ist erforderlich",
      newPasswordRequired: "Neues Passwort ist erforderlich",
      newPasswordMin: "Das Passwort muss mindestens 6 Zeichen lang sein",
      passwordsMustMatch: "Passwörter müssen übereinstimmen",

      firstNameMin: "Zu kurz!",
      firstNameMax: "Zu lang!",
      lastNameMin: "Zu kurz!",
      lastNameMax: "Zu lang!",
      phoneInvalid: "Telefonnummer ist ungültig",
      phoneRequired: "Telefonnummer ist erforderlich",
      passwordRequired: "Passwort ist erforderlich",
      passwordMin: "Das Passwort muss mindestens 8 Zeichen lang sein",
      passwordLowercase:
        "Das Passwort muss mindestens einen Kleinbuchstaben enthalten",
      passwordNumber: "Das Passwort muss mindestens eine Zahl enthalten",
    },
    labels: {
      firstname: "Vorname",
      lastname: "Nachname",
      email: "E-Mail",
      phone: "Telefon",
      password: "Passwort",
      oldPassword: "Altes Passwort",
      newPassword: "Neues Passwort",
      confirmNewPassword: "Neues Passwort bestätigen",
    },
    allowed: "Erlaubt",
    maxSize: "Maximale Größe von ",
  },
};

export default translationDe;
