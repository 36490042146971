import { useState } from "react";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import useSWR from "swr";
import { API } from "utils/axios/paths";
import {
  DeleteRounded,
  EditRounded,
  MoreVertOutlined,
  VerifiedUserRounded,
} from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { IUser } from "helpers/interfaces";
import MyTable from "components/tables";
import { IconButton, MenuItem, Popover } from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import Label from "components/label";
import UserTableToolbar from "../user-table-toolbar";
import useFetch from "hooks/useFetch";
import useUsers from "hooks/swr/useUsers";
import EditRoleModal from "./edit-role";
// ----------------------------------------------------------------------

export default function UserPage() {
  const [max, setMax] = useState(5);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [filterActive, setFilterActive] = useState<
    "active" | "all" | "inactive"
  >("all");
  const [openEditRole, setOpenEditRole] = useState(false);

  const { data, mutate, hasNext } = useUsers({
    page,
    max,
    filter,
  });

  const [open, setOpen] = useState<IUser | null>(null);

  const { call: verifyUser } = useFetch(
    "post",
    API.MANAGER.VERIFY_USER(open?.id || "")
  );

  const users: IUser[] | undefined =
    filterActive === "all"
      ? data
      : data?.filter(
          (user: IUser) => user.active === (filterActive === "active")
        );

  const handleOpenMenu = (record: IUser) => {
    setOpen(record);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleEditRole = () => {
    setOpenEditRole(true);
  };

  const handleEditRoleClose = () => {
    setOpenEditRole(false);
    handleCloseMenu();
    mutate();
  };

  // const handleDelete = () => {
  //   console.log("Delete");
  //   handleCloseMenu();
  // };

  const handleVerify = async () => {
    await verifyUser({});
    handleCloseMenu();
    mutate();
  };

  const handleFilterByName = (value: string) => {
    setFilter(value);
    mutate();
  };

  const handlePageChange = (
    event: any,
    newPage: number,
    rowsPerPage: number
  ) => {
    setPage(newPage);
    setMax(rowsPerPage);
    mutate();
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Users</Typography>
      </Stack>

      <MyTable
        dataSource={{
          data: !users || !users?.length ? [] : (users as IUser[]),
          key: "id",
        }}
        columns={[
          {
            id: "name",
            label: "Name",
            render: (record) => (
              <>
                {record.firstname} {record.lastname}
              </>
            ),
          },
          { id: "role", label: "Role", key: "role" },
          {
            id: "isActive",
            label: "Verified",
            align: "center",
            render: (record) =>
              record.active ? (
                <VerifiedIcon color="success" />
              ) : (
                <NewReleasesIcon color="warning" />
              ),
          },
          {
            id: "email",
            label: "Email",
            render: (record) => {
              return (
                <Label color={!record.active ? "warning" : "success"}>
                  {record.email}
                </Label>
              );
            },
          },
          {
            id: "actions",
            render: (record) => (
              <>
                <IconButton
                  id={record.id}
                  onClick={(e) => handleOpenMenu(record)}
                >
                  <MoreVertOutlined fontSize="small" />
                </IconButton>
              </>
            ),
          },
        ]}
        toolbar={
          <UserTableToolbar
            filterName={filter}
            filterActive={filterActive}
            onFilterActive={setFilterActive}
            onFilterName={handleFilterByName}
          />
        }
        pagination={{
          onPageChange: handlePageChange,
          rowsPerPage: max,
          hasNextPage: hasNext,
        }}
      />

      <Popover
        open={!!open}
        anchorEl={open?.id ? document.getElementById(open.id) : null}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={handleEditRole}>
          <EditRounded sx={{ mr: 1, height: 18 }} />
          Edit role
        </MenuItem>

        {!!open && !open.active && (
          <MenuItem onClick={handleVerify}>
            <VerifiedUserRounded sx={{ mr: 1, height: 18 }} />
            Verify
          </MenuItem>
        )}

        {/* <MenuItem onClick={handleDelete} sx={{ color: "error.main" }}>
          <DeleteRounded sx={{ mr: 1, height: 18 }} />
          Delete
        </MenuItem> */}
      </Popover>
      <EditRoleModal
        open={openEditRole}
        onClose={handleEditRoleClose}
        user={open as IUser}
      />
    </Container>
  );
}
